const DEFAULT_COLOR = '#adb0b4'
const GREEN = '#00ff00'
const YELLOW = '#ffb000'
const RED = '#ff0000'
const HIGHLIGHT_BORDER_WIDTH = '3px'
const DEFAULT_BORDER_WIDTH = '1px'

export const getLastKillStatusColor = (date) => {
    return Date.now() - new Date(date).getTime() > 600000 ? RED : GREEN
}

export const getSharksStatusColor = (sharks) => {
    if (sharks > 500) {
        return GREEN
    } else if (sharks <= 100) {
        return RED
    } else {
        return YELLOW
    }
}

export const getMembershipDaysStatusColor = (days) => {
    if (days >= 3) {
        return GREEN
    } else if (days < 2) {
        return RED
    } else {
        return YELLOW
    }
}

export const getSessionStatusColor = (lastKill, sharks, days) => {
    const subStatuses = [
        getLastKillStatusColor(lastKill),
        getSharksStatusColor(sharks),
        getMembershipDaysStatusColor(days)
    ]

    if (subStatuses.includes(RED)) {
        return RED
    }

    if (subStatuses.includes(YELLOW)) {
        return YELLOW
    }

    return DEFAULT_COLOR
}

export const getSessionCardBorderWidth = (lastKill, sharks, days) => {
    const subStatuses = [
        getLastKillStatusColor(lastKill),
        getSharksStatusColor(sharks),
        getMembershipDaysStatusColor(days)
    ]

    return subStatuses.every(status => status === GREEN) ? DEFAULT_BORDER_WIDTH : HIGHLIGHT_BORDER_WIDTH
}
import './App.css';
import api from './api/axiosConfig';
import { useState, useEffect } from 'react';
import {
    getLastKillStatusColor,
    getMembershipDaysStatusColor,
    getSessionCardBorderWidth,
    getSessionStatusColor,
    getSharksStatusColor,
} from './utils/sessionStatusUtils'
import { convertDate } from './utils/dateUtils';

function App() {

    const [sessions, setSessions] = useState([]);

    const getSessions = async () => {
        try {
            const response = await api.get('/api/v1/sessions')
            setSessions(response.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => { getSessions() }, [])

    return (
        <div className='session-container'>
            <h1>Sessions</h1>
            <div className='session-grid'>
                {
                    sessions.map((session, index) => (
                        <div
                            className='session-card'
                            key={index}
                            style={{
                                borderColor: getSessionStatusColor(session.lastKill, session.sharks, session.membershipDays),
                                borderWidth: getSessionCardBorderWidth(session.lastKill, session.sharks, session.membershipDays)
                            }}
                        >
                            <div>
                                <h3>{session.username}</h3>
                                Last kill: {convertDate(new Date(session.lastKill))}
                                <span
                                    className="circle"
                                    style={{
                                        backgroundColor: getLastKillStatusColor(session.lastKill)
                                    }}
                                /><br />
                                Total kills: {session.totalKills}
                                <br />
                                Sharks: {session.sharks}
                                <span
                                    className="circle"
                                    style={{
                                        backgroundColor: getSharksStatusColor(session.sharks)
                                    }}
                                /><br />
                                Membership days: {session.membershipDays}
                                <span
                                    className="circle"
                                    style={{
                                        backgroundColor: getMembershipDaysStatusColor(session.membershipDays)
                                    }}
                                /><br />
                                Deaths: {session.deaths}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default App;
